import { UrlMappingsKeys } from '@wix/url-mapper-utils';
import { get } from 'lodash';
import { CUSTOM_ROUTE_PLACEHOLDERS } from '../constants';
var generateCustomRoute = function (_a) {
    var state = _a.state, key = _a.key, placeholder = _a.placeholder, value = _a.value;
    if (!value) {
        return '';
    }
    var customPostRoute = get(getCustomRoutes(state), key);
    if (!customPostRoute) {
        return '';
    }
    return customPostRoute.replace(placeholder, value);
};
export var getCustomRoutes = function (state) { return get(state, 'customRoutes', null); };
export var getCustomPostUrl = function (state, slug) {
    return generateCustomRoute({
        state: state,
        key: UrlMappingsKeys.BLOG_POST,
        placeholder: CUSTOM_ROUTE_PLACEHOLDERS.postSlug,
        value: slug,
    });
};
export var getCustomPostEditUrl = function (state, id) {
    return generateCustomRoute({
        state: state,
        key: UrlMappingsKeys.BLOG_POST_EDIT,
        placeholder: CUSTOM_ROUTE_PLACEHOLDERS.postId,
        value: id,
    });
};
