import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { EXPERIMENT_POST_PAGE_SCROLL_TO_COMMENTS } from '@wix/communities-blog-experiments';
import { resolveId } from '@wix/communities-blog-client-common';
import { connect } from '../runtime-context';

import LikeButtonWithCount from '../like-button-with-count';
import PostSocialActions from '../post-social-actions';
import ViewCount from '../view-count';
import CategoryLabelList from '../category-label-list';
import PostLink from '../link/post-link';
import { HorizontalSeparator } from '../separator';
import withPermissions from '../../hoc/with-permissions';
import withAuth from '../../hoc/with-auth';
import withFeedMetadataSettings from '../../hoc/with-feed-metadata-settings';
import withExperiment from '../../hoc/with-experiment';
import withTranslate from '../../hoc/with-translate';
import { getFeedColorClassName } from '../../services/layout-config';
import {
  getViewCount,
  getCommentCount,
} from '../../store/post-counters/post-counters-selectors';
import { getSocialSharingProviders } from '../../selectors/app-settings-selectors';
import styles from './post-main-actions-desktop.scss';
import CommentCount from '../../../comments/components/comment-count';
import { CommentCountButton } from './post-main-actions-comment-count-button';

const PostMainActionsDesktop = ({
  anySocialActionOrCategory,
  canRender,
  commentsLink,
  enabledProviders,
  forPublicUser,
  getPostClassName,
  layoutName,
  incrementPostLikeCount,
  post,
  showCategoryLabels,
  showCommentCount,
  showLikeCount,
  showViewCount,
  showShareButtons,
  viewCount,
  totalComments,
  isExperimentScrollToCommentsEnabled,
  t,
}) => {
  const showCommentCountOrNew = commentsLink || totalComments;
  const isMetadataVisible =
    showLikeCount ||
    (showCommentCount && showCommentCountOrNew) ||
    showViewCount;
  const noSeparators = !anySocialActionOrCategory && !isMetadataVisible;
  const commentCount = showCommentCount ? (
    <CommentCount
      showZero
      count={totalComments}
      tabIndex={commentsLink ? -1 : 0}
    />
  ) : null;

  return (
    <div
      className={classNames(
        styles.container,
        { [styles.noSeparators]: noSeparators },
        getPostClassName(
          'description-font',
          getFeedColorClassName(layoutName, 'description-color'),
        ),
      )}
      data-hook="post-main-actions-desktop"
    >
      {anySocialActionOrCategory && [
        <HorizontalSeparator key="separator" />,
        <div
          key="div"
          className={classNames(styles.section, styles.mainSection)}
        >
          <div className={styles.socialActions}>
            {showShareButtons &&
              canRender('share', 'post', post, () => (
                <PostSocialActions
                  postSlug={post.slug}
                  postId={resolveId(post)}
                  enabledProviders={enabledProviders}
                />
              ))}
          </div>
          {showCategoryLabels && <CategoryLabelList post={post} />}
        </div>,
      ]}
      {isMetadataVisible && [
        <HorizontalSeparator key="separator" />,
        <div
          key="div"
          className={classNames(styles.section, styles.bottomSection)}
          data-hook="post-main-actions__stats"
        >
          <div data-hook="post-stats" className={styles.stats}>
            {showViewCount && <ViewCount count={viewCount} tabIndex={0} />}
            {showCommentCount &&
              (commentsLink ? (
                <PostLink
                  postLink={commentsLink}
                  postPath={post.slug}
                  className={classNames(
                    styles.comments,
                    getPostClassName('link-hashtag-hover-color'),
                  )}
                >
                  {commentCount}
                </PostLink>
              ) : (
                <CommentCountButton
                  ariaLabel={t('comment-count.button.aria-label')}
                  commentCount={commentCount}
                  isExperimentScrollToCommentsEnabled={
                    isExperimentScrollToCommentsEnabled
                  }
                />
              ))}
          </div>
          {showLikeCount && (
            <LikeButtonWithCount
              className={styles.likeButton}
              onClick={forPublicUser(() =>
                incrementPostLikeCount(resolveId(post)),
              )}
              entity={post}
            />
          )}
        </div>,
      ]}
    </div>
  );
};

PostMainActionsDesktop.defaultProps = {
  showShareButtons: true,
};

PostMainActionsDesktop.propTypes = {
  anySocialActionOrCategory: PropTypes.bool.isRequired,
  canRender: PropTypes.func,
  commentsLink: PropTypes.string,
  enabledProviders: PropTypes.array.isRequired,
  forPublicUser: PropTypes.func,
  getPostClassName: PropTypes.func.isRequired,
  layoutName: PropTypes.string,
  incrementPostLikeCount: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  showCategoryLabels: PropTypes.bool,
  showCommentCount: PropTypes.bool,
  showLikeCount: PropTypes.bool,
  showViewCount: PropTypes.bool,
  showShareButtons: PropTypes.bool,
  t: PropTypes.func,
  viewCount: PropTypes.number.isRequired,
  totalComments: PropTypes.number.isRequired,
  isExperimentScrollToCommentsEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = (
  state,
  { canSee, post, showCategoryLabels },
  actions,
) => {
  const enabledProviders = getSocialSharingProviders(state);
  const socialVisible =
    canSee('share', 'post', post) && enabledProviders.length;
  const categoryVisible =
    showCategoryLabels && post.categoryIds && post.categoryIds.length;
  return {
    anySocialActionOrCategory: Boolean(socialVisible || categoryVisible),
    enabledProviders,
    incrementPostLikeCount: actions.incrementPostLikeCount,
    viewCount: getViewCount(state, resolveId(post)),
    totalComments: getCommentCount(state, resolveId(post)),
  };
};

// prettier-ignore
export default flowRight(
  withPermissions,
  withFeedMetadataSettings,
  connect(mapStateToProps),
  withAuth,
  withExperiment({
    isExperimentScrollToCommentsEnabled: EXPERIMENT_POST_PAGE_SCROLL_TO_COMMENTS,
  }),
  withTranslate,
)(PostMainActionsDesktop);
