import React from 'react';
import styles from './accessibility-alert.scss';

const AccessibilityAlert = ({ message }) => {
  return (
    <div id="srmessage" role="alert" className={styles.alert}>
      {message}
    </div>
  );
};

export default AccessibilityAlert;
