// import s from '../styles/variables.scss';
// import b from '../styles/breakpoints.scss';
// HACK: temp fix. add prebuild step
var s = {
    postPageContainerPadding: '60px',
    postPagePadding: '20px',
    postPageMinWidth: '686px',
    postPageMaxWidth: '940px',
    postContentAreaMinWidth: '586px',
    postContentAreaMaxWidth: '740px',
    appComponentClassName: 'app-component',
};
var b = {
    breakpointPostContentAreaMin: '746px',
    breakpointPostContentAreaSmall: '900px',
};
export var POST_PAGE_CONTAINER_PADDING = parseInt(s.postPageContainerPadding, 10);
export var POST_PAGE_PADDING = parseInt(s.postPagePadding, 10);
export var POST_PAGE_MIN_WIDTH = parseInt(s.postPageMinWidth, 10);
export var POST_PAGE_MAX_WIDTH = parseInt(s.postPageMaxWidth, 10);
export var POST_CONTENT_AREA_MIN_WIDTH = parseInt(s.postContentAreaMinWidth, 10);
export var POST_CONTENT_AREA_MAX_WIDTH = parseInt(s.postContentAreaMaxWidth, 10);
export var BREAKPOINT_POST_CONTENT_AREA_MIN = parseInt(b.breakpointPostContentAreaMin, 10);
export var BREAKPOINT_POST_CONTENT_AREA_SMALL = parseInt(b.breakpointPostContentAreaSmall, 10);
export var DEFAULT_POST_TITLE_FONT_SIZE_MOBILE = 26;
export var DEFAULT_POST_PAGE_FONT_SIZE_MOBILE = 16;
export var DEFAULT_POST_HEADER_TWO_FONT_SIZE_MOBILE = 24;
export var DEFAULT_POST_HEADER_THREE_SIZE_MOBILE = 20;
export var DEFAULT_POST_HEADER_FOUR_SIZE_MOBILE = 18;
export var DEFAULT_POST_HEADER_FIVE_SIZE_MOBILE = 16;
export var DEFAULT_POST_HEADER_SIX_SIZE_MOBILE = 14;
export var DEFAULT_POST_QUOTES_SIZE_MOBILE = 20;
export var APP_COMPONENT_CLASS_NAME = s.appComponentClassName;
