import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import withTranslate from '../../hoc/with-translate';
import styles from './message.scss';

const Message = ({
  t,
  alignment,
  position,
  content,
  type,
  onClose,
  top,
  isInBackOffice,
  message,
  messageProps,
}) => {
  type = type || message.type;
  const isDefaultStyle = !type;
  const containerClassName = classNames(
    styles.container,
    styles[type],
    styles[alignment],
    styles[position],
    {
      'blog-card-color': isDefaultStyle,
      'blog-icon-secondary-background-color': isDefaultStyle,
    },
    {
      [styles.backOffice]: isInBackOffice,
    },
    'message',
  );
  const style = {
    top: `${top}px`,
  };

  content = content || message.content;
  if (typeof content !== 'function') {
    const element = content;
    content = () => React.Children.only(element);
  }

  return (
    <div className={containerClassName} style={style}>
      {content(t, messageProps)}
      {onClose && (
        <button
          className={classNames(styles.close, 'message__close')}
          onClick={onClose}
        />
      )}
    </div>
  );
};

Message.propTypes = {
  onClose: PropTypes.func,
  message: PropTypes.any,
  messageProps: PropTypes.any,
  alignment: PropTypes.string,
  position: PropTypes.string,
  top: PropTypes.number,
  t: PropTypes.func,
  isInBackOffice: PropTypes.bool,
};

Message.defaultProps = {
  top: 0,
  isInBackOffice: false,
};

export default withTranslate(Message);
