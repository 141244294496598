import { Component } from 'react';
import styles from './accessibility-listener.scss';

const ACTIVE_CLASS_NAME = 'blog-al-active';

export class AccessibilityListener extends Component {
  handleTabKeyUp = (e) => {
    if (e.which === 9 && document.body.classList.contains(styles.noOutline)) {
      document.body.classList.remove(styles.noOutline);
    }
  };

  handleClick = () => {
    if (!document.body.classList.contains(styles.noOutline)) {
      document.body.classList.add(styles.noOutline);
    }
  };

  componentDidMount() {
    if (document.body.classList.contains(ACTIVE_CLASS_NAME)) {
      return;
    }

    this.isActive = true;

    document.body.classList.add(ACTIVE_CLASS_NAME);
    document.body.classList.add(styles.noOutline);

    document.addEventListener('keyup', this.handleTabKeyUp);
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    if (this.isActive) {
      document.body.classList.remove(ACTIVE_CLASS_NAME);
    }
    if (this.isActive) {
      document.removeEventListener('keyup', this.handleTabKeyUp);
      document.removeEventListener('click', this.handleClick);
    }
  }

  render() {
    return null;
  }
}

export default AccessibilityListener;
