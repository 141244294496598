import React from 'react';
import withDeviceType from './with-device-type';

const forDevice = (ComponentMobile, ComponentDesktop) => {
  return withDeviceType(({ isMobile, ...props }) => {
    const Component = isMobile ? ComponentMobile : ComponentDesktop;
    return <Component {...props} />;
  });
};

export default forDevice;
