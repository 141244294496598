import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import { getImageUrl, getOuterUrl } from '@wix/communities-blog-client-common';

import Link from '../../../common/components/link/internal-link';
import withDeviceType from '../../../common/hoc/with-device-type';
import withPermissions from '../../../common/hoc/with-permissions';
import withSectionUrl from '../../../common/hoc/with-section-url';
import PostListItemVideoThumbnail from '../../../common/components/post-list-item-video-thumbnail';
import styles from './recent-post-list-item-cover.scss';
import withMediaHosts from '../../../common/hoc/with-media-hosts';

const RecentPostListItemCover = ({
  can,
  post,
  postLink,
  imageMetadata,
  videoMetadata,
  sectionUrl,
  imageHost,
}) => {
  const src = imageMetadata
    ? getImageUrl({
        imageHost,
        image: imageMetadata,
        maxWidth: 450,
        maxHeight: 253,
        type: 'fill',
        encode: true,
      })
    : videoMetadata.thumbnail_url;
  const isPublic = can('share', 'post', post);
  const pinProps = isPublic
    ? { 'data-pin-url': post.link || getOuterUrl(postLink, sectionUrl) }
    : { 'data-pin-nopin': true };

  return (
    <Link tabIndex="-1" fullRoute={post.link} to={postLink}>
      {imageMetadata ? (
        <div
          className={styles.cover}
          style={{ backgroundImage: `url(${src})` }}
          {...pinProps}
        ></div>
      ) : (
        <div className={styles.thumbContainer}>
          <div className={styles.thumbWrapper}>
            <PostListItemVideoThumbnail
              oembed={videoMetadata}
              withoutLabel={true}
            />
          </div>
        </div>
      )}
    </Link>
  );
};

RecentPostListItemCover.propTypes = {
  can: PropTypes.func,
  post: PropTypes.object,
  postLink: PropTypes.string,
  imageMetadata: PropTypes.object,
  imageHost: PropTypes.string,
  videoHost: PropTypes.string,
  isMobile: PropTypes.bool,
  videoMetadata: PropTypes.object,
  sectionUrl: PropTypes.string,
};

export default flowRight(
  withDeviceType,
  withPermissions,
  withSectionUrl,
  withMediaHosts,
)(RecentPostListItemCover);
