import {
  SOCIAL_SHARING_FACEBOOK,
  SOCIAL_SHARING_TWITTER,
  SOCIAL_SHARING_LINKED_IN,
  SOCIAL_SHARING_LINK,
  SOCIAL_SHARING_PRINT,
} from '@wix/communities-blog-client-common';
import { FacebookIcon } from '../components/icons/facebook-icon';
import { TwitterIcon } from '../components/icons/twitter-icon';
import { LinkedInIcon } from '../components/icons/linked-in-icon';
import { LinkIcon } from '../components/icons/link-icon';
import { PrintIcon } from '../components/icons/print-icon';

const ICONS = {
  [SOCIAL_SHARING_FACEBOOK]: FacebookIcon,
  [SOCIAL_SHARING_TWITTER]: TwitterIcon,
  [SOCIAL_SHARING_LINKED_IN]: LinkedInIcon,
  [SOCIAL_SHARING_LINK]: LinkIcon,
  [SOCIAL_SHARING_PRINT]: PrintIcon,
};

export const getSocialSharingIcon = (provider) => ICONS[provider];
