import { get } from 'lodash';
import { DEFAULT_LANGUAGE } from '@wix/communities-blog-universal/dist/src/constants/blog-languages';
export var getAppData = function (state) { return state.appData; };
export var getFeedTitle = function (state) { return getAppData(state).menuLabel; };
export var getLanguage = function (state) {
    return getAppData(state).language || DEFAULT_LANGUAGE;
};
export var getAmpEnabled = function (state) {
    return get(getAppData(state), 'ampEnabled', false);
};
export var getSchemaEnabled = function (state) {
    return get(getAppData(state), 'schemaEnabled', false);
};
export var getPostPageEnabled = function (state) {
    return get(getAppData(state), 'postPageEnabled', false);
};
export var getSendEmailsToSiteSubscribers = function (state) {
    return get(getAppData(state), 'sendEmailsToSiteSubscribers', false);
};
export var getSendEmailsToSiteMembers = function (state) {
    return get(getAppData(state), 'sendEmailsToSiteMembers', false);
};
