export var MODAL_TYPE_POST_EDITOR = 'post-editor';
export var MODAL_TYPE_POST_FORM_EDIT_IN_PROGRESS = 'post-form-edit-in-progress';
export var MODAL_TYPE_ADD_CATEGORIES = 'add-categories';
export var MODAL_TYPE_DELETE_CATEGORY = 'delete-category';
export var MODAL_TYPE_DISCARD_POST = 'discard-post';
export var MODAL_TYPE_DISCARD_POST_DRAFT = 'discard-post-draft';
export var MODAL_TYPE_DELETE_COMMENT = 'delete-comment';
export var MODAL_TYPE_DELETE_POST = 'delete-post';
export var MODAL_TYPE_MOVE_TO_TRASH = 'move-to-trash';
export var MODAL_TYPE_PREVIEW_POST = 'preview-post';
export var MODAL_TYPE_PROFILE_ACTIONS = 'profile-actions';
export var MODAL_TYPE_PROFILE_ACTIONS_UPPER_PART = 'profile-actions-upper-part';
export var MODAL_TYPE_REPORT_COMMENT = 'report-comment';
export var MODAL_TYPE_REPORT_POST = 'report-post';
export var MODAL_TYPE_REPORT_MEMBER = 'report-member';
export var MODAL_TYPE_SHARE_POST = 'share-post';
export var MODAL_TYPE_SHARE_LINK = 'share-link';
export var MODAL_TYPE_SHARE_COMMENT = 'share-comment';
export var MODAL_TYPE_DEMO_MODE = 'demo-mode';
export var MODAL_POST_FORM_ACTIONS = 'post-form-actions';
export var MODAL_TYPE_POST_SETTINGS = 'post-settings';
export var MODAL_TYPE_DISCARD_POST_SETTINGS = 'discard-post-settings';
export var MODAL_TYPE_RICH_CONTENT_EDITOR = 'rich-content-editor';
export var MODAL_TYPE_REVERT_TO_DRAFT = 'revert-to-draft';
export var MODAL_TYPE_LOADER = 'loader';
export var MODAL_TYPE_IMPORT_WIX_SITE = 'import-wix-posts';
