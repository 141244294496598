import { __assign, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import RicosViewer from './ricos-viewer';
import styles from './ricos-viewer-wrapper.scss';
var RicosViewerWrapper = /** @class */ (function (_super) {
    __extends(RicosViewerWrapper, _super);
    function RicosViewerWrapper() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            viewerRef: null,
        };
        _this.setViewerRef = function (element) { return _this.setState({ viewerRef: element }); };
        return _this;
    }
    RicosViewerWrapper.prototype.render = function () {
        var _a = this.props, isMobile = _a.isMobile, TextSelectionToolbar = _a.TextSelectionToolbar, TwitterButton = _a.TwitterButton, isTwitterEnabled = _a.isTwitterEnabled;
        return (React.createElement("div", { className: styles.relativeContainer },
            React.createElement(RicosViewer, __assign({}, this.props, { setViewerRef: this.setViewerRef })),
            isTwitterEnabled && !isMobile ? (React.createElement(TextSelectionToolbar, { container: this.state.viewerRef }, function (selectedText) { return React.createElement(TwitterButton, { selectedText: selectedText }); })) : null));
    };
    return RicosViewerWrapper;
}(React.Component));
RicosViewerWrapper.propTypes = {
    TextSelectionToolbar: PropTypes.elementType.isRequired,
    TwitterButton: PropTypes.elementType.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isTwitterEnabled: PropTypes.bool,
};
export default RicosViewerWrapper;
