import PropTypes from 'prop-types';
import React from 'react';
import styles from './simple-layout.scss';

const SimpleLayout = ({ children }) => (
  <div className={styles.container}>{children}</div>
);

SimpleLayout.propTypes = {
  children: PropTypes.node,
};

export default SimpleLayout;
