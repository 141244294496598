import PropTypes from 'prop-types';
import React from 'react';
import EmptyStates from '../empty-states';
import withTranslate from '../../hoc/with-translate';
import Button from '../button';
import Link from '../link/internal-link';

const NotFound = ({ t, ...props }) => (
  <EmptyStates
    focusOnLoad={true}
    title={t('not-found.nothings-showing-up')}
    content={t('not-found.head-back')}
    {...props}
  >
    <Button component={Link} to="/">
      {t('not-found.back')}{' '}
    </Button>
  </EmptyStates>
);

NotFound.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslate(NotFound);
