import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../runtime-context';
import { isEditor } from '../../store/basic-params/basic-params-selectors';
import ScrollListener from '../scroll-listener';

class OnScreen extends React.Component {
  state = { isOnScreen: false };
  elementRef = null;

  isOnScreen() {
    const rect = this.elementRef && this.elementRef.getBoundingClientRect();
    return (
      Boolean(rect) && rect.top < window.innerHeight + this.props.threshold
    );
  }

  onScroll = () => {
    if (this.isOnScreen()) {
      this.setState({ isOnScreen: true });
    }
  };

  componentDidMount() {
    this.onScroll();
  }

  setRef = (element) => (this.elementRef = element);

  render() {
    const isOnScreen = this.state.isOnScreen || this.props.isEditor;
    return (
      <>
        {this.props.children({ isOnScreen, setRef: this.setRef })}
        {!isOnScreen && <ScrollListener onScroll={this.onScroll} />}
      </>
    );
  }
}

OnScreen.propTypes = {
  threshold: PropTypes.number.isRequired,
  isEditor: PropTypes.bool.isRequired,
};

OnScreen.defaultProps = {
  threshold: 200,
};

const mapRuntimeToProps = (state) => ({
  isEditor: isEditor(state),
});

export default connect(mapRuntimeToProps)(OnScreen);
