import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import {
  getIsAppBundle,
  getIsPostPageBundle,
} from '@wix/communities-blog-client-common';

import { connect } from '../runtime-context';
import HeaderNavigation from '../header-navigation';
import DesktopHeader from '../desktop-header';
import SearchInput from '../search-input';
import withBlogMenuSettings from '../../hoc/with-blog-menu-settings';
import HeaderWidget from '../header-widget';
import styles from './category-desktop-header.scss';
import { getPostBySlug } from '../../selectors/post-selectors';

const renderSearchInput = (post) => (
  <SearchInput
    post={post}
    inputClassName="blog-desktop-header-search-text-color blog-desktop-header-search-font"
    borderClassName="blog-desktop-header-search-border-background-color"
    iconClassName="blog-desktop-header-search-icon-fill"
  />
);

const CategoryDesktopHeader = (props) => {
  const {
    showCategoryLabels,
    showSearch,
    isAppBundle,
    isPostPageBundle,
    post,
  } = props;
  return (
    <DesktopHeader
      className={classNames(
        styles.container,
        isAppBundle && styles.noMargin,
        isPostPageBundle && styles.inPostPage,
      )}
      left={showCategoryLabels ? <HeaderNavigation /> : <div />}
      right={
        <HeaderWidget
          searchInput={showSearch ? renderSearchInput(post) : null}
        />
      }
      {...props}
    />
  );
};

CategoryDesktopHeader.propTypes = {
  showSearch: PropTypes.bool.isRequired,
  showCategoryLabels: PropTypes.bool.isRequired,
  isAppBundle: PropTypes.bool.isRequired,
  isPostPageBundle: PropTypes.bool.isRequired,
  postSlug: PropTypes.string,
};

const mapRuntimeToProps = (state, { postSlug }) => ({
  isAppBundle: getIsAppBundle(state),
  isPostPageBundle: getIsPostPageBundle(state),
  post: postSlug ? getPostBySlug(state, postSlug) : null,
});

export default flowRight(
  withBlogMenuSettings,
  connect(mapRuntimeToProps),
)(CategoryDesktopHeader);
