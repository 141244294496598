import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import DotDotDot from '../dotdotdot';
import SimpleRenderer from '../simple-renderer';
import styles from './content-excerpt.scss';

const ContentExcerpt = ({
  content,
  excerpt,
  type,
  lineCount,
  maxLength,
  autoFit,
}) => {
  const className = classNames(styles.container, styles[type], 'post-excerpt');
  const clamp = autoFit ? 'parent' : lineCount;
  return (
    <div className={className}>
      <DotDotDot clamp={clamp} useExactLineHeight={true}>
        <SimpleRenderer
          contentState={content}
          excerpt={excerpt}
          maxLength={maxLength}
        />
      </DotDotDot>
    </div>
  );
};

ContentExcerpt.propTypes = {
  content: PropTypes.object,
  excerpt: PropTypes.string,
  lineCount: PropTypes.number.isRequired,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  autoFit: PropTypes.bool,
};

export default ContentExcerpt;
