import { dateLib } from '@wix/communities-blog-client-common';

export const calculateReadingTimeProps = ({
  scrollPosition,
  scrollableHeight,
  clientHeight,
  browserWidth,
  readingStartTime,
  contentContainer,
}) => ({
  percent_scrolled: Math.round((scrollPosition / scrollableHeight) * 100),
  browser_width: browserWidth,
  view_start: readingStartTime.utc().format(),
  timediff: dateLib.utc().diff(readingStartTime),
  scrollable_heights: scrollableHeight,
  scrolled_bottom: Math.round(scrollPosition + clientHeight),
  scrolled_top: Math.round(scrollPosition),
  bottom_position: contentContainer
    ? Math.round(contentContainer.bottom + scrollPosition)
    : 0,
  top_position: contentContainer
    ? Math.round(contentContainer.top + scrollPosition)
    : 0,
});
