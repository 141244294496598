import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import Link from '../link/internal-link';
import styles from './seo-select.scss';

const SeoSelect = ({ links, activeLink }) => (
  <nav
    className={classNames(
      styles.dropdown,
      'blog-navigation-container-background-color',
    )}
  >
    <ul id="toggle-seo-nav">
      {links.map(({ path, text }, i) => (
        <Link key={i} to={path}>
          {text}
        </Link>
      ))}
    </ul>
    <a href="#toggle-seo-nav" className={styles.button}>
      {activeLink.text}
    </a>
    <a href="#" className={classNames(styles.button, styles.close)}>
      Close
    </a>
  </nav>
);

SeoSelect.propTypes = {
  links: PropTypes.array.isRequired,
  activeLink: PropTypes.object.isRequired,
};

export default SeoSelect;
