import { resolveId } from '@wix/communities-blog-client-common';

export const getHeaderLinks = ({
  categories,
  feedTitle,
  categoryPath,
  totalPublicationsCount = 0,
  withFeedLink = true,
} = {}) =>
  categories.reduce(
    (acc, category) => {
      acc.push({
        key: resolveId(category),
        path: `/${categoryPath}/${category.slug}`,
        text: category.menuLabel,
        postAmount: category.postAmount,
      });
      return acc;
    },
    withFeedLink
      ? [
          {
            key: 'latest_posts',
            path: '',
            text: feedTitle,
            postAmount: totalPublicationsCount,
          },
        ]
      : [],
  );
