import { get } from 'lodash';
import { isVerboseDebugAllowed } from './debug';

const simulateError = (queryParam, isSSR, origin) => {
  if (queryParam === 'ssr' && isSSR) {
    throw new Error(`SSR error in ${origin}`);
  } else if (queryParam === 'csr' && !isSSR) {
    throw new Error(`CSR error in ${origin}`);
  }
};

export const simulateControllerError = (wixCodeApi, origin) => {
  if (!isVerboseDebugAllowed) {
    return;
  }
  const isSSR = get(wixCodeApi, 'window.rendering.env') === 'backend';
  const queryParam = get(wixCodeApi, 'location.query.simulateError');
  simulateError(queryParam, isSSR, origin);
};

export const simulateComponentError = (basicParams, origin) => {
  if (!isVerboseDebugAllowed) {
    return;
  }
  const isSSR = get(basicParams, 'isSSR');
  const url = get(basicParams, 'url', '');
  let queryParam;
  if (url.includes('simulateError=csr2')) {
    queryParam = 'csr';
  } else if (url.includes('simulateError=ssr2')) {
    queryParam = 'ssr';
  }
  simulateError(queryParam, isSSR, origin);
};
