import { EXPERIMENT_CONTENT_MIGRATION_ADMIN } from '@wix/communities-blog-experiments';
import { isExperimentEnabled } from './experiments-selectors';
import { MIGRATION_STATUS_CONTENT_SYNC_COMPLETE, MIGRATION_STATUS_REDIRECTS_SET_COMPLETE, MIGRATION_ROLLBACK_ALLOWED_STATUSES, MIGRATION_REDIRECTS_PENDING_STATUSES, } from '../constants/migration-status';
export var getContentMigration = function (state) { return state.contentMigration; };
export var getContentMigrationStatus = function (state) {
    return getContentMigration(state).status;
};
export var getContentMigrationFailedPosts = function (state) {
    return getContentMigration(state).failedPosts || [];
};
export var isPostsTransfered = function (state) {
    return getContentMigrationStatus(state) === MIGRATION_STATUS_CONTENT_SYNC_COMPLETE;
};
export var isRedirectsSet = function (state) {
    return getContentMigrationStatus(state) === MIGRATION_STATUS_REDIRECTS_SET_COMPLETE;
};
export var isFirstTime = function (state) { return getContentMigration(state).isFirstTime; };
var isSyncOrRedirectComplete = function (state) {
    return [
        MIGRATION_STATUS_CONTENT_SYNC_COMPLETE,
        MIGRATION_STATUS_REDIRECTS_SET_COMPLETE,
    ].includes(getContentMigrationStatus(state));
};
export var isContentMigrationEnabled = function (state) {
    return (isExperimentEnabled(state, EXPERIMENT_CONTENT_MIGRATION_ADMIN) &&
        getContentMigration(state).isOldBlogInstalled) ||
        isSyncOrRedirectComplete(state);
};
export var isContentMigrationAdminControlsEnabled = function (state) {
    return isContentMigrationEnabled(state) &&
        isExperimentEnabled(state, EXPERIMENT_CONTENT_MIGRATION_ADMIN) &&
        MIGRATION_ROLLBACK_ALLOWED_STATUSES.includes(getContentMigrationStatus(state));
};
export var isRedirectsNotSet = function (state) {
    return MIGRATION_REDIRECTS_PENDING_STATUSES.includes(getContentMigrationStatus(state));
};
