import { flowRight, map } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Slider from 'react-slick';
import { resolveId } from '@wix/communities-blog-client-common';

import RecentPostsListItem from '../recent-posts-list-item';
import Dot from './dot';
import withCardBorderWidth from '../../../common/hoc/with-card-border-width';
import withFeedMetadataSettings from '../../../common/hoc/with-feed-metadata-settings';
import { getIsRTL } from '../../../common/store/basic-params/basic-params-selectors';
import { connect } from '../../../common/components/runtime-context';
import styles from './recent-post-carousel.scss';

const sliderProps = {
  arrows: false,
  className: styles.container,
  dots: true,
  useCSS: true,
  infinite: true,
  customPaging: Dot,
  centerMode: true,
  centerPadding: '30px',
  slidesToShow: 1,
  slidesToScroll: 1,
};

const RecentPostCarousel = ({
  borderWidth,
  isRTL,
  posts = [],
  isMetadataFooterVisible,
}) => (
  <div dir="ltr">
    <Slider {...sliderProps}>
      {map(posts, (post) => (
        <div
          className={styles.slide}
          key={resolveId(post)}
          dir={isRTL ? 'rtl' : undefined}
        >
          <div
            className={classNames(styles.postWrapper, 'blog-card-border-color')}
            style={{ borderWidth }}
          >
            <RecentPostsListItem
              post={post}
              isMetadataFooterVisible={isMetadataFooterVisible}
            />
          </div>
        </div>
      ))}
    </Slider>
  </div>
);

RecentPostCarousel.propTypes = {
  isRTL: PropTypes.bool.isRequired,
  posts: PropTypes.array.isRequired,
  borderWidth: PropTypes.number,
  isMetadataFooterVisible: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state) => ({
  isRTL: getIsRTL(state),
});

export default flowRight(
  withCardBorderWidth,
  connect(mapRuntimeToProps),
  withFeedMetadataSettings,
)(RecentPostCarousel);
