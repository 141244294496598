var _a;
import { SECTION_HOMEPAGE, SECTION_CATEGORY } from '../../constants/sections';
import { POST_TITLE_COLOR, POST_TITLE_FONT, POST_TEXT_COLOR, POST_PAGE_FONT, BUTTON_COLOR, BUTTON_TEXT_COLOR, } from '../../constants/wix-params';
import { getSiteColor, setColorOpacity } from '../../services/colors';
import { getSiteFont } from '../../services/fonts';
var DEFAULT = 'default';
var wixParamMap = (_a = {},
    _a[DEFAULT] = {
        titleColor: POST_TITLE_COLOR,
        titleFont: POST_TITLE_FONT,
        textColor: POST_TEXT_COLOR,
        textFont: POST_PAGE_FONT,
    },
    _a[SECTION_HOMEPAGE] = {
        titleColor: 'post-homepage-titleColor',
        titleFont: 'post-full-homepage-titleFont',
        textColor: 'post-homepage-descriptionColor',
        textFont: 'post-full-homepage-descriptionFont',
    },
    _a[SECTION_CATEGORY] = {
        titleColor: 'post-category-titleColor',
        titleFont: 'post-full-category-titleFont',
        textColor: 'post-category-descriptionColor',
        textFont: 'post-full-category-descriptionFont',
    },
    _a);
export var getBannerStyle = function (_a) {
    var appSettings = _a.appSettings, _b = _a.section, section = _b === void 0 ? DEFAULT : _b;
    var wixParams = wixParamMap[section];
    var titleColor = getSiteColor(appSettings, {
        wixParam: wixParams.titleColor,
        presetFallback: 'color-5',
        colorFallback: '#2f2e2e',
    });
    var titleFont = getSiteFont(appSettings, {
        wixParam: wixParams.titleFont,
        presetFallback: 'Body-M',
        fontFallback: "'open sans',sans-serif",
        onlyFamily: true,
    });
    var textColor = getSiteColor(appSettings, {
        wixParam: wixParams.textColor,
        presetFallback: 'color-5',
        colorFallback: '#000',
    });
    var textFont = getSiteFont(appSettings, {
        wixParam: wixParams.textFont,
        presetFallback: 'Body-M',
        fontFallback: "'open sans',sans-serif",
        onlyFamily: true,
    });
    var borderColor = setColorOpacity(textColor, 0.2);
    var buttonColor = getSiteColor(appSettings, {
        wixParam: BUTTON_COLOR,
        presetFallback: 'color-8',
        colorFallback: '#3899ec',
    });
    var buttonTextColor = getSiteColor(appSettings, {
        wixParam: BUTTON_TEXT_COLOR,
        presetFallback: 'color-1',
        colorFallback: '#fff',
    });
    return {
        '--paywall-title-color': titleColor,
        '--paywall-title-font': titleFont,
        '--paywall-text-color': textColor,
        '--paywall-text-font': textFont,
        '--paywall-button-color': buttonColor,
        '--paywall-button-text-color': buttonTextColor,
        '--paywall-border-color': borderColor,
    };
};
