var DATE_LOCALES = {
    tl: 'tl-ph',
    zh: 'zh-tw',
    no: 'nb',
    pt: 'pt-br',
};
var DEFAULT_DATE_FORMAT = 'MMM D';
var DATE_FORMATS = {
    ko: 'MMM D일',
    ja: 'MMMDo',
    vi: 'D MMMM',
};
var DEFAULT_FULL_DATE_FORMAT = 'll';
var FULL_DATE_FORMATS = {
    vi: 'D MMMM YYYY',
};
export var getDateLocale = function (lng) { return DATE_LOCALES[lng] || lng; };
export var getDateFormat = function (lng) { return DATE_FORMATS[lng] || DEFAULT_DATE_FORMAT; };
export var getFullDateFormat = function (lng) {
    return FULL_DATE_FORMATS[lng] || DEFAULT_FULL_DATE_FORMAT;
};
