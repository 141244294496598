export var MIGRATION_STATUS_NOT_STARTED = 'NOT_STARTED';
export var MIGRATION_STATUS_CONTENT_SYNC_STARTED = 'CONTENT_SYNC_STARTED';
export var MIGRATION_STATUS_CONTENT_SYNC_FAILED = 'CONTENT_SYNC_FAILED';
export var MIGRATION_STATUS_CONTENT_SYNC_COMPLETE = 'CONTENT_SYNC_COMPLETE';
export var MIGRATION_STATUS_REDIRECTS_SET_CONFIRM = 'REDIRECTS_SET_CONFIRM';
export var MIGRATION_STATUS_REDIRECTS_SET_STARTED = 'REDIRECTS_SET_STARTED';
export var MIGRATION_STATUS_REDIRECTS_SET_FAILED = 'REDIRECTS_SET_FAILED';
export var MIGRATION_STATUS_REDIRECTS_SET_COMPLETE = 'REDIRECTS_SET_COMPLETE';
export var MIGRATION_ROLLBACK_ALLOWED_STATUSES = [
    MIGRATION_STATUS_CONTENT_SYNC_COMPLETE,
    MIGRATION_STATUS_CONTENT_SYNC_FAILED,
    MIGRATION_STATUS_REDIRECTS_SET_COMPLETE,
    MIGRATION_STATUS_REDIRECTS_SET_FAILED,
];
export var MIGRATION_REDIRECTS_PENDING_STATUSES = [
    MIGRATION_STATUS_CONTENT_SYNC_COMPLETE,
    MIGRATION_STATUS_REDIRECTS_SET_CONFIRM,
    MIGRATION_STATUS_REDIRECTS_SET_STARTED,
    MIGRATION_STATUS_REDIRECTS_SET_FAILED,
];
export var OLD_BLOG_APP_DEFINITION_ID = '61f33d50-3002-4882-ae86-d319c1a249ab';
