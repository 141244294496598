import { flowRight, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import classNames from 'classnames';
import { connect } from '../../../components/runtime-context';
import { getMessage } from '../store/message-selectors';
import styles from './message-root.scss';
import {
  MESSAGE_ERROR,
  MESSAGE_INFO,
  MESSAGE_IMPORTANT,
} from '../messages-types';

const getA11yPropForMessageType = (level) => {
  switch (level) {
    case MESSAGE_ERROR:
      return { role: 'alert' };
    case MESSAGE_INFO:
      return { 'aria-live': 'polite' };
    case MESSAGE_IMPORTANT:
      return { 'aria-live': 'assertive' };
    default:
      return {};
  }
};

const CLOSE_TIMEOUT = 3000;
const ANIMATION_TIMEOUT = 400;

export function createMessageRoot({
  MessageComponent,
  closeTimeout = CLOSE_TIMEOUT,
  animationTimeout = ANIMATION_TIMEOUT,
  messages,
  className,
}) {
  class MessageRoot extends Component {
    componentDidUpdate(prevProps) {
      if (this.props.messageType !== prevProps.messageType) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.hideMessage, closeTimeout);
      }
    }

    hideMessage = () => {
      this.props.hideMessage();
      this.timeout = undefined;
    };

    render() {
      const { messageType, messageProps, hideMessage } = this.props;

      const message = messages[messageType];
      const a11yProps = getA11yPropForMessageType(get(message, 'type'));

      return (
        <CSSTransitionGroup
          component="div"
          transitionName="message"
          transitionEnterTimeout={animationTimeout}
          transitionLeaveTimeout={animationTimeout}
        >
          <div
            className={classNames(className || styles.container, 'message')}
            data-hook="message"
            {...a11yProps}
          >
            {message ? (
              <MessageComponent
                message={message}
                messageProps={messageProps}
                onClose={hideMessage}
              />
            ) : null}
          </div>
        </CSSTransitionGroup>
      );
    }
  }

  MessageRoot.propTypes = {
    hideMessage: PropTypes.func.isRequired,
    messageType: PropTypes.string,
    messageProps: PropTypes.any,
  };
  const mapRuntimeProps = (state, ownProps, actions) => {
    const message = getMessage(state);
    return {
      messageType: message.type,
      messageProps: message.props,
      hideMessage: () => actions.hideMessage(),
    };
  };

  return flowRight(connect(mapRuntimeProps))(MessageRoot);
}
