import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Link from '../link/internal-link';
import { EXPERIMENT_MOBILE_MENU_COLOR_MATCH_DESKTOP } from '@wix/communities-blog-experiments';
import { SearchIcon } from '../icons/search-icon';
import classNames from 'classnames';
import withTranslate from '../../hoc/with-translate';
import withExperiment from '../../hoc/with-experiment';
import styles from './search-button.scss';

class SearchButton extends Component {
  render() {
    const { t, isMobileMenuColorMatchDesktopExperimentEnabled } = this.props;

    const iconClassName = isMobileMenuColorMatchDesktopExperimentEnabled
      ? 'blog-navigation-container-fill'
      : 'blog-button-primary-icon-fill';

    return (
      <Link to="/search" className={classNames(styles.button, 'search-button')}>
        <SearchIcon className={iconClassName} />
        <span className={styles.hiddenLabel}>{t('search-button.search')}</span>
      </Link>
    );
  }
}

SearchButton.propTypes = {
  t: PropTypes.func.isRequired,
  isMobileMenuColorMatchDesktopExperimentEnabled: PropTypes.bool,
};

export default flowRight(
  withExperiment({
    isMobileMenuColorMatchDesktopExperimentEnabled:
      EXPERIMENT_MOBILE_MENU_COLOR_MATCH_DESKTOP,
  }),
  withTranslate,
)(SearchButton);
